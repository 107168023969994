@import '../../styles/constants';
@import '../../styles/colors';
@import '../../styles/mixins';

.membership-gift-cards {

  &__wrapper {
    min-height: 400px;
    margin: auto;
    max-width: 1532px;
    display: flex;
    gap: 40px;

    @include tabletSmall() {
      flex-direction: column;
      gap: 20px;
    }
  }

  &__title {
    height: 194px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    user-select: none;
    z-index: 1;
    transform: translateY(-55px);
    @include tabletSmall() {
      height: 148px;
      transform: translateY(-37px);
    }

    @include mobile() {
      height: 142px;
    }

    &--membership {
      @include mobile() {
        height: 114px;
        transform: translateY(-43px);
      }
    }

    p {
      font-size: 18px;
      font-weight: 600;

      @include tablet() {
        font-size: 16px;
      }
    }

    h3 {
      font-size: 32px;
      font-weight: 600;
      margin-top: 36px;

      br {
        display: none;
      }

      @include tablet() {
        font-size: 24px;
      }

      @include tabletSmall() {
        font-size: 18px;
        margin-top: 20px;

        br {
          display: block;
        }
      }
    }
  }

  &__desktopCopy {
    display: block;

    @include tabletSmall() {
      display: none;
    }
  }

  &__mobileCopy {
    display: none;

    @include tabletSmall() {
      display: block;
    }
  }

  &__cta {
    button {
      user-select: none;
      width: 224px;
      height: 44px;

      @include mobile() {
        font-size: 11px;
        width: 152px;
        height: 25px;
        padding: initial;
      }
    }
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      @include tabletSmall() {
        object-position: 50% 84%;
      }

      @include mobile() {
        object-position: 50% 100%;
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      opacity: 0.4;
      background: linear-gradient(0deg, #191919 50.4%, rgba(0, 0, 0, 0) 100%);
    }
  }

  &__card {
    transform: translateY(60px);
    width: 284px;
    position: relative;
    aspect-ratio: 1.54;

    @include tabletSmall() {
      width: 160px;      
      transform: translateY(40px);
    }

    @include mobile() {
      width: 156px;
    }
  }

  &__strap {
    // transform: translateY(38px);

    svg {
      width: 100%;
    }
    transform: translateY(7px);

    @include tabletSmall() {
      width: 65px;
      height: 72px;
      transform: translateY(-32px);
    }

    @include mobile() {
      transform: translateY(-34px);
    }
  }

  &__block {
    position: relative;
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    height: 729px;

    @include desktop() {
      height: 729px;
    }

    @include tabletSmall() {
      flex: 1 1 auto;
      height: 379px;
    }

    &--membership {
      background-color: #6bb7e3;
      color: white;
    }

    &--gift {
      background-color: #f6f0e5;
      color: $black;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      @include tabletSmall() {
        height: 445px;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1.8px;

        @include tabletSmall() {
          font-size: 11px;
        }
      }
    }
  }
}
