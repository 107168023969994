.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  min-height: 650px;

  .hero {
    grid-column: span 2;
    position: relative;

    > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 50px 0 0 30px;
      gap: 50px;

      > *:first-child {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }

  .article {
    grid-column: span 1;
    cursor: pointer;
    position: relative;

    div {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      padding: 10px;
      gap: 50px;
      max-width: 80%;
    }
  }
}

@media (min-width: 768px) {
  .container {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    width: 100%;
    min-height: 400px;
    .hero {
      grid-column: span 2;

      h4 {
        max-width: 125px;
        font-size: 36px;
      }

      p {
        font-size: 18px;
        font-weight: 500;
        max-width: 356px;
      }

      > div {
        padding-left: 90px;
        padding-bottom: 60px;
        gap: 60px;

        justify-content: flex-end;

        > *:first-child {
          gap: 20px;
        }
      }
    }

    .article {
      > div {
        padding: 20px;
        gap: 20px;

        p {
          font-size: 24px;
        }
      }
    }
  }
}

@media (min-width: 1650px) {
  .container {
    min-height: 650px;
  }
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  max-height: 50%;
  opacity: 0.7;
  background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
}
