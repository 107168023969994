@import '../../styles/colors';
@import '../../styles/constants';
@import '../../styles/mixins';

.mylerhome {
  width: 100%;
  max-width: 2368px;
  height: 742px;
  margin: 50px auto 129px;
  position: relative;
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.2;
      background: linear-gradient(180deg, #191919 0%, rgba(25, 25, 25, 0) 100%);
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    width: 510px;
    min-height: 300px;
    padding: 78px 20px 0;

    @include mobile() {
      padding: 40px 20px 0;
    }

    div {
      text-align: center;
      color: $white;
    }
  }

  &__txt {
    &--1 {
      font-size: 24px;
      font-weight: 600;

      @include tabletSmall() {
        font-size: 18px;
        font-weight: 500;
        padding: 0 24px;
      }
    }

    &--2 {
      font-size: 28px;
      line-height: 33.6px;
      letter-spacing: 3.8px;
      text-transform: uppercase;
      margin-top: 29px;
      font-weight: 600;

      @include tabletSmall() {
        font-size: 22px;
        letter-spacing: 1px;
        margin-top: 34px;
        line-height: 30.6px;
        padding: 0 70px;
      }

      @include mobile() {
        padding: 0 24px;
      }
    }

    &--3 {
      font-size: 14px;
      line-height: 20px;
      margin-top: 44px;
      font-weight: 500;

      @include tabletSmall() {
        margin-top: 36px;
      }
    }
  }

  &__cta {
    display: flex;
    justify-content: center;
    margin-top: 46px;

    @include mobile() {
      margin-top: 40px;
    }

    button {
      user-select: none;
      width: 224px;
      height: 44px;

      @include mobile() {
        font-size: 11px;
        width: 145px;
        height: 36px;
        padding: initial;
      }
    }
  }
}

.hide {
  &--tabletSmall {
    @include tabletSmall() {
      display: none;
    }
  }
  &--mobile {
    @include mobile() {
      display: none;
    }
  }
}
