.default {
  text-align: center;
  border-radius: 5px;
  font-weight: 600;
  line-height: 15.4px;
  border: none;
  outline: none;
  cursor: pointer;

  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
}

.primary-gold {
  background-color: var(--gold-dark);
  color: var(--black);
}

.primary-black {
  background-color: var(--black);
  color: var(--white);
}

.secondary-dark-grey {
  background-color: var(--grey-dark);
  color: var(--white);
}

.secondary-white {
  background-color: var(--white);
  color: var(--black);
}

.secondary-ghost {
  background-color: transparent;
  color: var(--black);
}

.secondary-white,
.secondary-ghost {
  border: 1px solid var(--grey-dark);
}

/*disabled*/

.primary-gold:disabled,
.primary-black:disabled,
.secondary-dark-grey:disabled {
  background-color: var(--grey-medium);
  color: var(--white);
}

.secondary-white:disabled,
.secondary-ghost:disabled {
  background-color: var(--grey-light);
  color: var(--grey-dark);
}

.primary-gold:disabled,
.primary-black:disabled,
.secondary-dark-grey:disabled,
.secondary-white:disabled,
.secondary-ghost:disabled {
  cursor: not-allowed;
}

/* sizes */

.full {
  padding: 15px 0;
  width: 100%;
}

.large {
  padding: 15px 30px;
}

.medium {
  padding: 10px 30px;
}

.small {
  padding: 5px 20px;
}

.xs {
  padding: 5px 15px;
}

.small,
.xs {
  font-size: 12px;
}
