.h1,
.h2,
.h3,
.h4,
.h5 {
  margin-block: 0;
}

.h1,
.h2,
.h3,
.h4 {
  font-weight: 600;
  line-height: 120%;
}

.h1 {
  font-size: 36px;
  font-weight: 600;
}

.h2 {
  font-size: 32px;
  font-weight: 600;
}

.h3 {
  font-size: 28px;
}

.h4 {
  font-size: 24px;
}

.h5 {
  font-size: 18px;
  font-weight: 500;
  line-height: 120%;
}
