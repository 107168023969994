.heroContainer {
  position: relative;

  .heroContent {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
}
