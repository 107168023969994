@import '../../styles/colors';
@import '../../styles/constants';
@import '../../styles/mixins';

.carouselContainer {
  user-select: none;
  position: relative;
  width: 100%;
  --slide-spacing: 30px;
}

.carouselButtons {
  height: 44px;
  position: absolute;
  transform: translateY(-100%);
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 26px;
  padding-right: 54px;

  button {
    border: 0;
    width: 24px;
    height: 20px;
    background-color: transparent;
    cursor: pointer;

    &:disabled {
      opacity: 0.3;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.slideNumber {
  font-size: 12px;
  width: 50px;
}

.carouselViewport {
  overflow: hidden;
  width: 100%;
}

.carouselSlidesContainer {
  display: flex;
  margin-left: calc(var(--slide-spacing) * -1);
  > * {
    padding-left: var(--slide-spacing);
  }
}
