@import '../../styles/colors';
@import '../../styles/constants';
@import '../../styles/mixins';

.hotelSlide {
  flex: 0 0 470px;

  cursor: pointer;

  &:last-child {
    padding-right: 20px;
  }

  @include desktop() {
    flex: 0 0 404px;
  }

  @include tabletSmall() {
    flex: 0 0 82%;
    padding-left: 20px;
  }
}

.hotelSlideContent {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.hotelSlideImage {
  position: relative;
  min-height: 395px;
}

.hotelSlideName {
  position: relative;
  flex: 0 0 32px;
  display: flex;
  align-items: flex-end;
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

@media screen and (min-width: 768px) {
  .hotelSlideImage {
    min-height: 594px;
  }
}
