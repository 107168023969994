.small {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0;
}

.xs {
  font-size: 11px;
}
