.carousel {
  grid-column: span 2;
  width: 100%;
  max-width: 1224px;
  padding: 30px 30px 30px;
  margin: 0 auto;
}

.title {
  text-align: center;
  font-family: 'Avenir Next';
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.container {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  margin-top: 20px;
}

.container::-webkit-scrollbar {
  display: none;
}

.content {
  display: inherit;
  gap: 30px;
}

.group {
  scroll-snap-align: center;
  display: flex;
  width: calc(100vw - 77px);
  max-width: 1170px;
  gap: 30px;
  justify-content: center;
  overflow: hidden;
}

.image {
  width: 250px;
  height: 150px;
  padding: 40px;
  border-radius: 10px;
  border: 1px solid hsl(39, 30%, 61%);
  background-color: hsl(0, 0%, 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.hotelLogos {
  width: 208px;
  height: 108px;
  object-fit: scale-down;
}

.buttons {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  top: calc(150px / 2 * -1);
}

.button_prev {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(-100%) translateY(-50%);
  z-index: 2;
  cursor: pointer;
  border: 0px solid transparent;
  background-color: transparent;
}

.button_prev svg {
  width: 30px;
  height: 30px;
}

.button_next {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(100%) translateY(-50%);
  z-index: 2;
  cursor: pointer;
  border: 0px solid transparent;
  background-color: transparent;
}

.button_next svg {
  width: 30px;
  height: 30px;
}

.partner {
  background-color: var(--gold-light);
}

.partner img {
  mix-blend-mode: multiply;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

@media screen and (min-width: 600px) {
  .carousel {
    padding: 130px 30px 110px;
  }
  .hotelLogos {
    width: 100%;
    height: 100%;
  }
  .titleContainer {
    display: flex;
    font-size: 24px;
  }
}

/* desktop */
@media screen and (min-width: 1024px) {
  .container {
    margin-top: 81px;
  }
}
