@import '../../styles/colors';
@import '../../styles/constants';
@import '../../styles/mixins';

.container {
  display: flex;
  width: 100%;
  max-width: 100vw;
  margin: 46px auto;
  max-width: 2368px;

  @include tabletSmall() {
    flex-direction: column;
    margin: 28px auto;
  }
}

.title {
  flex: 0 0 396px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 87px;
  padding-bottom: 5px;

  @include tabletSmall() {
    flex: 0 0 auto;
    padding-right: inherit;
    justify-content: center;
    padding-bottom: 50px;
  }
  @include mobile() {
    padding-bottom: 50px;
  }

  &Inner {
    width: 224px;
    height: 324px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    color: $black;
    transform: translateY(10px);
    @include tabletSmall() {
      height: 203px;
      width: auto;
      transform: translateY(19px);
    }
  }

  h3 {
    font-size: 32px;

    @include tabletSmall() {
      font-size: 24px;
      padding-bottom: 16px;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.3;
    letter-spacing: 0.1px;
    margin-bottom: 11px;

    @include tabletSmall() {
      font-size: 14px;
      margin-bottom: 13px;
    }
  }

  &SVG {
    @include tabletSmall() {
      height: 36px;
      margin-bottom: 7px;
    }
  }

  svg {
    transform: translateX(-3px);
    margin-bottom: 17px;

    @include tabletSmall() {
      height: 100%;
      margin-bottom: 0;
      transform: translateX(0px);
    }
  }

  button {
    padding: 15px 60px;

    @include tabletSmall() {
      padding: 10px 23px;
    }
  }
}

.carousel {
  flex: 1 1 auto;
  // specific styles for this implementation of the carousel
  .carouselSlidesContainer {
    --slide-spacing: 20px;
    @include desktop() {
      --slide-spacing: 16px;
    }
    @include tabletSmall() {
      margin-left: 0;
      padding-left: var(--slide-spacing);
    }
  }
  .carouselButtons {
    @include tabletSmall() {
      display: none;
    }
  }
}
