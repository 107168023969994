.baseText {
  font-family: 'Avenir Next';
  font-size: 14px;
  line-height: 140%;
  margin: 0;
  padding: 0;
  letter-spacing: 0;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}
